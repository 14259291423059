import React from "react";
import styles from "./footer.module.css";
import web from "../../assets/icons/web.webp";
import email from "../../assets/icons/email.webp";
//import phone from "../../assets/icons/phone.webp";

export function Footer() {
  return (
    <div className={styles.container}>
      <div className={styles.mainContent}>
        <div className={styles.section}>
          <img className={styles.icon} src={web} alt={"web icon"} />{" "}
          <a
            className={styles.link}
            target="_blank"
            rel="noreferrer"
            href={"https://www.converse-x-chat.com"}
          >
            {" "}
            www.converse-x-chat.com
          </a>
        </div>
        <div className={styles.section}>
          {" "}
          <img className={styles.icon} src={email} alt={"email icon"} />{" "}
          <a
            className={styles.link}
            target="_blank"
            rel="noreferrer"
            href="mailto:sales@converse-x-chat.com"
          >
            {" "}
            sales@converse-x-chat.com
          </a>
        </div>
        {/*<div className={styles.section}>
          {" "}
          <img className={styles.icon} src={phone} alt={"phone icon"} />{" "}
          <a
            className={styles.link}
            target="_blank"
            rel="noreferrer"
            href="tel:+49123456789"
          >
            {" "}
            +49 [0] 234 56 789
          </a>
        </div>*/}
      </div>
    </div>
  );
}
