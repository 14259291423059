import React from "react";
import styles from "./welcome.module.css";
import { Converse } from "../Converse";

export function Welcome() {
  return (
    <div className={styles.container}>
      <div className={styles.headerSection}>
        <h2 className={styles.mainHeader}>🚀&nbsp;WILLKOMMEN BEI {<Converse />}</h2>
        <h4 className={styles.subHeader}>
            Deinem Sprungbrett in die Welt des profitablen
            Chat-Business!&nbsp;💬&nbsp;💼
        </h4>
      </div>
      <p className={styles.description}>

      </p>
      <p className={styles.description}>
          Entfessle das volle Potenzial deiner Geschäftsidee mit {<Converse/>}, der ultimativen
          Lösung für Chat-basierte Geschäftsaktivitäten. Egal, ob du eine leidenschaftliche
          Startup-Idee verfolgst oder dein bestehendes Unternehmen auf das nächste Level heben
          möchtest,{<Converse/>} hat alles, was du brauchst.

      </p>
    </div>
  );
}
